import './scripts/publicPath';
import 'console-polyfill';
import './main.scss';
import $ from 'jquery';
import feather from 'feather-icons';
import scrollama from 'scrollama';

import installCE from 'document-register-element/pony';

window.jQuery = $;

window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.preloadAfterLoad = true;
require('lazysizes');

$(document).ready(function () {
    feather.replace({
        'stroke-width': 1,
    });

    // Open External Links In New Window
    $('a').each(function () {
        if ($(this).attr("href") && $(this).attr("href").indexOf("#") === 0) {
            return;
        }
        if ($(this).attr("href") && $(this).attr("href").indexOf("javascript") === 0) {
            return;
        }
        if (!$(this).attr("href")){
            return;
        }
        var a = new RegExp('/' + window.location.host + '/');
        var b = new RegExp(/(mailto:.*)/g);
        if (!a.test(this.href) && !b.test(this.href)) {
            $(this).attr('rel', 'external');
            $(this).attr("target", "_blank");
        } else {
            $(this).attr('rel', 'internal');
        }
    });
});

installCE(window, {
    type: 'force',
    noBuiltIn: true,
});

function importAll(r) {
    r.keys().forEach(r);
}

importAll(require.context('../Components/', true, /\/script\.js$/));

// eslint-disable-next-line no-unused-vars
const scroller = scrollama();

// setup the instance, pass callback functions
scroller
    .setup({
        step: '.fade-in',
        once: true,
        offset: 0.9,
    })
    .onStepEnter(({element}) => {
        element.classList.add('entered');
        // { element, index, direction }
    });

// setup resize event
window.addEventListener('resize', scroller.resize);

