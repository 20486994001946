import $ from 'jquery';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class NavigationBurger extends window.HTMLElement {
  constructor(...args) {
    const self = super(...args);
    self.init();
    return self;
  }

  init() {
    this.$ = $(this);
    this.bindFunctions();
    this.bindEvents();
    this.resolveElements();
    this.setNavSpacer();
  }

  bindFunctions() {
    this.triggerMenu = this.triggerMenu.bind(this);
    this.setNavSpacer = this.setNavSpacer.bind(this);
  }

  bindEvents() {
    const { setNavSpacer } = this;
    this.$.on('click', '[data-toggle-menu]', this.triggerMenu);

    this.$window = $(window);

    // Sticky menu
    if (this.$window.width() > 766) {
      window.addEventListener('scroll', () => {
        var windowTop = $(window).scrollTop();
        windowTop > 100
          ? this.$.addClass('header-sticky')
          : this.$.removeClass('header-sticky');
      });
    }

    // Dropdown Menu

    this.$dropdown = $('#navigationBurger-menu li.has-children', this);
    this.$dropdown.on('click', (event) => {
      if (this.$window.width() > 767) return;
      this.$parent = $(event.target).parent('li');
      this.$parent.toggleClass('open');
    });

    this.$window.on('resize', () => {
      clearTimeout(window.resizedFinished);
      window.resizedFinished = setTimeout(() => {
        console.log('hello');
        setNavSpacer();
      }, 250);
    });
  }

  resolveElements() {
    this.$menu = $('.menu', this);
    this.$menuBurger = $('.menu-burger', this);
    this.$menuButton = $('.hamburger', this);
  }

  connectedCallback() {}

  triggerMenu(e) {
    this.$.toggleClass('flyntComponent-menuIsOpen');
    this.$menuBurger.toggleClass('is-open');
    this.$menuButton.attr(
      'aria-expanded',
      this.$menuButton.attr('aria-expanded') === 'false' ? 'true' : 'false'
    );
    if (this.$.hasClass('flyntComponent-menuIsOpen')) {
      disableBodyScroll(this.$menu.get(0));
      $('body').addClass('menu-isOpen');

      this.$menuBurger.find('.level-0').each(function (i) {
        $(this).css({ transitionDelay: `0.${35 + 5 * i}s` });
      });
    } else {
      enableBodyScroll(this.$menu.get(0));
      $('body').removeClass('menu-isOpen');
    }
  }

  setNavSpacer() {
    var navHeight = this.$.height();
    this.$.closest('header.mainHeader')
      .find('.header-nav-spacer')
      .css('padding-top', navHeight);
  }
}

window.customElements.define('flynt-navigation-main', NavigationBurger, {
  extends: 'nav',
});
