import $ from 'jquery';

class SiteSearch extends window.HTMLDivElement {
  constructor(...args) {
    const self = super(...args);
    self.init();
    return self;
  }

  init() {
    this.$ = $(this);
    this.body = $('body');
    this.props = this.getInitialProps();
    this.state = this.getInitialState();
    this.resolveElements();
    this.bindFunctions();
    this.bindEvents();
  }

  getInitialProps() {
    const data = {};
    // try {
    //   data = JSON.parse($('script[type="application/json"]', this).text())
    // } catch (e) {}
    return data;
  }

  getInitialState() {
    return {};
  }

  resolveElements() {}

  bindFunctions() {}

  bindEvents() {
    const body = this.body;
    body.on('click', '.js-toggle-site-search', (e) => {
      e.preventDefault();
      body.toggleClass('is-search-open');
    });
  }

  connectedCallback() {}
}

window.customElements.define('flynt-site-search', SiteSearch, {
  extends: 'div',
});
