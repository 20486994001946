import $ from 'jquery';
import 'objectFitPolyfill';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

class HeroSliderTextImage extends window.HTMLDivElement {
  constructor(...args) {
    const self = super(...args);
    self.init();
    return self;
  }

  init() {
    this.$ = $(this);
    this.props = this.getInitialProps();
    this.resolveElements();
  }

  getInitialProps() {
    let data = {};
    try {
      data = JSON.parse($('script[type="application/json"]', this).text());
    } catch (e) {}
    return data;
  }

  resolveElements() {
    this.$slider = $('[data-slider]', this);
    this.$sliderItems = $('.swiper-slide', this);
    this.$buttonNext = $('[data-slider-button="next"]', this);
    this.$buttonPrev = $('[data-slider-button="prev"]', this);
    this.$pagination = $('[data-slider-pagination]', this);
  }

  connectedCallback() {
    if (this.$sliderItems.length > 1) {
      this.initSlider();
      this.setArrowBackground();
    }
  }

  initSlider() {
    const { options } = this.props;
    const config = {
      a11y: options.a11y,
      navigation: {
        nextEl: this.$buttonNext.get(0),
        prevEl: this.$buttonPrev.get(0),
      },
      slidesPerView: 1,
      pagination: {
        el: '.slider-pagination',
        clickable: true,
      },
    };

    if (options.autoplay && options.autoplaySpeed) {
      config.autoplay = {
        delay: options.autoplaySpeed,
      };
    }

    this.swiper = new Swiper(this.$slider.get(0), config);

    this.swiper.on('slideChangeTransitionEnd', this.setArrowBackground);
  }

  setArrowBackground() {
    var buttons = $('.bg-color-js');
    var activeItem = $('.swiper-slide-active');
    var color = activeItem.css('background-color');

    buttons.css('background-color', color);
  }
}

window.customElements.define(
  'flynt-hero-slider-text-image',
  HeroSliderTextImage,
  {
    extends: 'div',
  }
);
