import $ from 'jquery';
import Masonry from 'masonry-layout';

// const $window = $(window);

class BlockMasonry extends window.HTMLDivElement {
  constructor(...args) {
    const self = super(...args);
    self.init();
    return self;
  }

  init() {
    this.$ = $(this);
    this.resolveElements();
  }

  resolveElements() {
    this.$elem = $('.grid-masonry', this);
    this.$item = $('.grid-item', this);
  }

  connectedCallback() {
    if (this.$elem.length > 0) {
      this.initMasonry();
    }
  }

  initMasonry() {
    var msnry = new Masonry('.grid-masonry', {
      // options
      itemSelector: '.grid-item',
      columnWidth: '.grid-item',
    });
  }

  // isMobile() {
  //   return window.matchMedia('(max-width: 767px)').matches;
  // }
}

window.customElements.define('flynt-block-masonry', BlockMasonry, {
  extends: 'div',
});
